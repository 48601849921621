import React, { useEffect } from "react"

import "../../assets/sass/main.scss"
import "typeface-roboto"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { connect } from "react-redux"
import { setCorrespondingLangSlug, setToLanguage } from "../../state/actions"
import Section from "../../components/section"
import SectionInner from "../../components/section-inner"
// import "../post/single-post.scss"
import GatsbyImage from "gatsby-image"
import { useTranslation } from "react-i18next"
import IconLink from "../../components/icon-link"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import contentParser from "gatsby-wpgraphql-inline-images"


const SingleFaq = (
  {
    setCorrespondingLangSlug,
    pageContext: {
      pluginOptions,
      faq
    },
    correspondingLangSlug, lang,
  },
) => {
  const { i18n, t } = useTranslation()


  const correspondingTranslation = faq.translations.find((translation) => {
    return translation.language.locale !== faq.language.locale
  })

  useEffect(() => {
    if (faq.language.locale !== lang) {
      setToLanguage(faq.language.locale)
      i18n.changeLanguage(faq.language.locale)
    }

    if (correspondingTranslation && correspondingTranslation.fullSlug !== correspondingLangSlug) {
      setCorrespondingLangSlug(correspondingTranslation.fullSlug)
    }
  })

  return (
    <Layout>
      <SEO title={faq.title} lang={faq.language.locale}/>

      <Section className="SinglePost">
        <SectionInner className="SinglePost-inner Section--bPadding u-textMaxWidth u-applyListStyle">
          {
            faq.featuredImage &&
            (
              (faq.featuredImage.imageFile && faq.featuredImage.imageFile.extension !== "svg") ?
                <GatsbyImage className="SinglePost-cover" fluid={faq.featuredImage.imageFile.childImageSharp.fluid}
                             alt={faq.featuredImage.altText}/>
                :
                <img className="SinglePost-cover"
                     src={faq.featuredImage.imageFile ? faq.featuredImage.imageFile.publicURL : faq.featuredImage.sourceUrl}
                     alt={faq.featuredImage.altText}/>
            )
          }

          <IconLink className="SinglePost-backLink"
                    to={faq.language.locale === "de_DE" ? "/ressourcen/faq" : "/en/resources/faq"}
                    icon={NavigateBeforeIcon} before>{t("Zurück zur FAQ Übersicht")}</IconLink>


          <h1 className="SinglePost-title t-h1" dangerouslySetInnerHTML={{__html: faq.title}} />

          {/*<div className="SinglePost-body" dangerouslySetInnerHTML={{ __html: faq.content }}/>*/}
          <div className="SinglePost-body">
            {contentParser({ content: faq.content }, pluginOptions)}
          </div>

        </SectionInner>

      </Section>

    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
    correspondingLangSlug: state.correspondingLangSlug,
  }
}

const mapDispatchToProps = {
  setCorrespondingLangSlug,
  setToLanguage,
}


export default connect(mapStateToProps, mapDispatchToProps)(SingleFaq)
